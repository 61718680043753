import {
  getOperatingAccountBalance,
  getSafeGaurdingBalance
} from '../../store/actions/rdaDashboardActions';

export default function (fetch, baseUrl) {
  return {
    getRdaPots({ active, pageSize, page }) {
      return fetch.get(
        `${baseUrl}/pots?active=${active}&pageNo=${page}&pageSize=${pageSize}`
      );
    },
    getRdaPotsLast30Days() {
      return fetch.get(`${baseUrl}/last30days`);
    },
    getFxRate() {
      return fetch.get(`${baseUrl}/fx-rate`);
    },
    createPot({ payload }) {
      return fetch.post(`${baseUrl}/pots`, payload);
    },
    deletePot({ potId }) {
      return fetch.delete(`${baseUrl}/pots?potId=${potId}`);
    },
    getRdaProvider({ provider }) {
      return fetch.get(`${baseUrl}/provider/${provider}`);
    },
    updateRdaProvider({ provider, status, reason }) {
      return fetch.put(`${baseUrl}/provider`, { provider, status, reason });
    },
    getRdaTransactions: (filters) => {
      return fetch.get('/admin/rda-dashboard', { params: filters });
    },

    getRdaTransactionDetails: (orderId) => {
      return fetch.get(`/admin/rda-dashboard/${orderId}`);
    }
  };
}
