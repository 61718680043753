import OffRampData from '../../components/vdaDashboard/OffRampData';
import { setAlert } from '../actions/alertActions';
import { falcon_ops as types } from '../constants/falconConstants';

const initialState = {
  reqBody: {
    search_key: null,
    search_value: null,
    filter_key: null,
    filter_value: [],
    page_number: 0,
    page_size: 10,
    sort_key: null,
    sort_order: null
  },
  transactionsData: {
    falconTransactionsList: [],
    currentPage: 0,
    totalPages: 1,
    totalResults: 0
  },
  transaction: {},
  isLoading: false,
  isLoadingDetails: false
};

const falconTransactionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case types.GETTING_FALCON_TRANSACTIONS:
      return { ...state, isLoading: true };

    case types.GET_FALCON_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        isLoadingTransactionList: false,
        transactionsData: {
          falconTransactionsList: [...payload.data],
          currentPage: payload.page,
          totalPages: payload.totalPages,
          totalResults: payload.totalResults
        },
        isLoading: false
      };

    case types.GET_FALCON_TRANSACTIONS_FAILURE:
      return {
        ...state,
        isLoading: false
      };

    case types.GETTING_FALCON_TRANSACTION_DETAILS:
      return {
        ...state,
        isLoadingDetails: true
      };

    case types.GET_FALCON_TRANSACTION_DETAILS_SUCCESS:
      return {
        ...state,
        isLoadingDetails: false,
        transaction: { ...payload }
      };

    case types.GET_FALCON_TRANSACTION_DETAILS_FAILURE:
      return {
        ...state,
        isLoadingTransaction: false
      };

    default:
      return state;
  }
};
export default falconTransactionReducer;
