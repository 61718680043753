const RDA = {
  GETTING_ALL_POTS: 'GETTING_ALL_POTS',
  GET_ALL_POTS_SUCCESS: 'GET_ALL_POTS_SUCCESS',
  GET_ALL_POTS_FAILURE: 'GET_ALL_POTS_FAILURE',
  CREATING_POT: 'CREATING_POT',
  CREATE_POT_SUCCESS: 'CREATE_POT_SUCCESS',
  CREATE_POT_FAILURE: 'CREATE_POT_FAILURE',

  GET_ALL_POTS: 'GET_ALL_POTS',
  CREATE_POT: 'CREATE_POT',
  DELETE_POT: 'DELETE_POT',
  GET_FX_RATE: 'GET_FX_RATE',

  UPDATE_ACTIVE_ONLY: 'UPDATE_ACTIVE_ONLY',

  GETTING_RDA_TRANSACTIONS: 'GETTING_RDA_TRANSACTIONS',
  GET_RDA_TRANSACTIONS_SUCCESS: 'GET_RDA_TRANSACTIONS_SUCCESS',
  GET_RDA_TRANSACTIONS_FAILURE: 'GET_RDA_TRANSACTIONS_FAILURE',

  GETTING_RDA_TRANSACTION_DETAILS: 'GETTING_RDA_TRANSACTION_DETAILS',
  GET_RDA_TRANSACTION_DETAILS_SUCCESS: 'GET_RDA_TRANSACTION_DETAILS_SUCCESS',
  GET_RDA_TRANSACTION_DETAILS_FAILURE: 'GET_RDA_TRANSACTION_DETAILS_FAILURE',

  GETTING_GBP_INR_BALANCE: 'GET_GBP_INR_BALANCE',
  GETTING_GBP_INR_BALANCE_SUCCESS: 'GETTING_GBP_INR_BALANCE_SUCCESS',
  GETTING_GBP_INR_BALANCE_FAILURE: 'GETTING_GBP_INR_BALANCE_FAILURE'
};

export default RDA;
