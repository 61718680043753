import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Textarea,
  VStack,
  FormControl
} from '@chakra-ui/react';
import { useState, useEffect } from 'react';

const CreatePot = ({
  isLoading = false,
  onCancel = () => {},
  onSubmit = (data) => {}
}) => {
  const [gbpValue, setGBPValue] = useState(0);
  const [fxRate, setFxRate] = useState(0);
  const [inrCredited, setInrCredited] = useState(0);
  const [reference, setReference] = useState('');
  const [error, setError] = useState(false);

  const handleSubmit = async (e) => {
    e?.preventDefault();
    onSubmit && onSubmit({ gbpValue, fxRate, inrCredited, reference });
  };
  const handleINRCreditedChange = (e) => {
    const inrAmount = e?.target?.value;
    setInrCredited(inrAmount);
  };

  return (
    <>
      <ModalHeader>Create Pot</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <FormControl invalid={error}>
            <VStack alignItems={'stretch'} gap={2}>
              <Box>
                <FormLabel>GBP Value</FormLabel>
                <Input
                  type="number"
                  step="0.01"
                  onChange={(e) => setGBPValue(e?.target?.value)}
                />
                <FormLabel>Fx Rate</FormLabel>
                <Input
                  type="number"
                  step="0.01"
                  onChange={(e) => setFxRate(e?.target?.value)}
                />
                <FormLabel>INR Credited</FormLabel>
                <Input
                  type="number"
                  step="0.01"
                  onChange={handleINRCreditedChange}
                />
                {/* {error ? (
                  <Text fontSize="sm" color="red.500" mt={2}>
                    INR Credited Amount is incorrect
                  </Text>
                ) : (
                  <FormHelperText>Fx Rate * GBP Amount</FormHelperText>
                )} */}
                <FormLabel>Reference</FormLabel>
                <Textarea
                  type="text"
                  onChange={(e) => setReference(e?.target?.value)}
                />
              </Box>
            </VStack>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack justifyContent={'end'} gap={2}>
            <Button
              type="button"
              variant={'outline'}
              colorScheme="red"
              onClick={onCancel}
              disabled={isLoading}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              variant={'outline'}
              colorScheme="brand"
              isLoading={isLoading}
            >
              Create
            </Button>
          </HStack>
        </ModalFooter>
      </form>
    </>
  );
};

export default CreatePot;
