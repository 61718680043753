import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ContentLayout from '../../layouts/ContentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { updateKycDocsReqData } from '../../store/actions/reqDataActions';
import {
  clearAllKycs,
  clearKycDocs,
  clearUserKyc,
  getKycDocs,
  getUserKyc,
  rejectEfrKyc,
  syncUserKyc
} from '../../store/actions/kycActions';
import {
  KYC_DOC_STATUS,
  KYC_PROVIDER,
  KYC_STATUS,
  KYC_V2_STATUS,
  PRODUCT,
  ROLES
} from '../../constants';
import { getKycV2StatusColor, getVerificationTitle } from '../../utils';
import { Link as ReactLink, useParams } from 'react-router-dom';
import { RxDashboard } from 'react-icons/rx';

import useDidMountEffect from '../../hooks/useDidMount';
import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  Spinner,
  VStack,
  Link,
  HStack,
  Text,
  useDisclosure,
  Icon,
  Button
} from '@chakra-ui/react';
import Pagination from '../../components/Pagination';
import KycDocCard from '../../components/Kyc/KycDocCard';
import { BsArrowRight } from 'react-icons/bs';
import ModalLayout from '../../components/Modal/ModalLayout';
import JsonViewer from '../../components/Modal/JsonViewer';
import KycUpdateModal from '../../components/Modal/KycUpdateModal';
import { setAlert } from '../../store/actions/alertActions';
import {
  clearUserDetails,
  getUserById,
  updateUserKycDetailsList
} from '../../store/actions/usersActions';
import useIsMobile from '../../hooks/useIsMobile';
import UserSideCard from '../../components/UserSideCard';
import { AiOutlineSync } from 'react-icons/ai';
import CopyableText from '../../components/CopyableText';
import useAuthRole from '../../hooks/useAuthRole';
import { getOcrDetails } from '../../store/actions/nronboardActions';
import SaberUpdateOcrModal from '../../components/Kyc/UpdateOcrModal';
import { updateOcrByDoc } from '../../store/actions/workFlowsActionsV2';
import ReKycOcrModal from '../../components/Kyc/ReKycOcrModal';
import VerifyAllDocsModal from '../../components/Kyc/VerifyAllDocsModal';
import {
  isAllowedKycProviderForSync,
  getProviderDashboardConfigOrNull
} from './kycUiUtils';

const KycDocs = () => {
  const isMobile = useIsMobile();
  const { userId, product, provider } = useParams();
  const {
    data: docs,
    isLoading,
    totalPages
  } = useSelector((state) => state.kyc.user.docs);
  const { data: kycDetails } = useSelector((state) => state.kyc.user.kyc);
  const { data: userDetails } = useSelector((state) => state.users.userDetails);
  const dispatch = useDispatch();
  const searchReqData = useSelector((state) => state.reqData.kyc.user.docs);
  const kycSearchReqData = useSelector((state) => state.reqData.kyc.user.kyc);

  const [isSyncLoading, setIsSyncLoading] = useState(false);
  const [isVerifyAllDocsLoading, setIsVerifyAllDocsLoading] = useState(false);

  const {
    isOpen: isKycInfoOpen,
    onOpen: onKycInfoOpen,
    onClose: onKycInfoClose
  } = useDisclosure();

  const {
    isOpen: isUpdateKycStatusOpen,
    onOpen: onUpdateKycStatusOpen,
    onClose: onUpdateKycStatusClose
  } = useDisclosure();

  const {
    isOpen: isRemarksOpen,
    onOpen: onRemarksOpen,
    onClose: onRemarksClose
  } = useDisclosure();

  const {
    isOpen: isRejectReasonOpen,
    onOpen: onRejectReasonOpen,
    onClose: onRejectReasonClose
  } = useDisclosure();

  const {
    isOpen: isSaberVerifyOpen,
    onOpen: onSaberVerifyOpen,
    onClose: onSaberVerifyClose
  } = useDisclosure();

  const {
    isOpen: isOnRampMoneyVerifyOpen,
    onOpen: onRampMoneyVerifyOpen,
    onClose: onRampMoneyVerifyClose
  } = useDisclosure();

  const {
    isOpen: isEncryptusVerifyOpen,
    onOpen: onEncryptusVerifyOpen,
    onClose: onEncryptusVerifyClose
  } = useDisclosure();

  /* in on-ramp-money context  */
  const {
    isOpen: isVerifyAllDocsOpen,
    onOpen: onVerifyAllDocsOpen,
    onClose: onVerifyAllDocsClose
  } = useDisclosure();
  /* in on-ramp-money context  */

  const [activeDoc, setActiveDoc] = useState(null);
  const [statusUpdateData, setStatusUpdateData] = useState(null);
  const [activeRemarks, setActiveRemarks] = useState(null);
  const [activeRejectReason, setActiveRejectReason] = useState(null);
  const [rjctBtnLoading, setRjctBtnLoading] = useState(false);
  const [passportFrontOcr, setPassportFrontOcr] = useState({});
  const [isORKycBtnLoading, setORKycBtnLoading] = useState(false);
  const [ocrData, setOcrData] = useState({});

  const { hasAnyAuthority } = useAuthRole();

  useEffect(() => {
    if (!docs?.length) triggerGetKycDocs(searchReqData);
    if (!kycDetails) triggerGetUserKyc(kycSearchReqData);
    triggerGetUserDetails(userId);
    // for OCR data
    provider === KYC_PROVIDER.SABER &&
      product === PRODUCT.REMITTANCE &&
      getUserOcrDetails({ setPassportFrontOnly: true });
    provider === KYC_PROVIDER.ON_RAMP_MONEY &&
      getUserOcrDetails({ setPassportFrontOnly: false });
    provider === KYC_PROVIDER.ENCRYPTUS &&
      getUserOcrDetails({ setPassportFrontOnly: false });

    return () => {
      dispatch(clearKycDocs());
      dispatch(clearUserKyc());
      dispatch(clearUserDetails());
    };
  }, []);

  useDidMountEffect(() => {
    triggerGetKycDocs(searchReqData);
  }, [searchReqData]);

  useDidMountEffect(() => {
    triggerGetUserKyc(kycSearchReqData);
  }, [kycSearchReqData]);

  useEffect(() => {
    if (activeDoc) onKycInfoOpen();
    else onKycInfoClose();
  }, [activeDoc]);

  useEffect(() => {
    if (statusUpdateData) onUpdateKycStatusOpen();
    else onUpdateKycStatusClose();
  }, [statusUpdateData]);

  useEffect(() => {
    if (activeRemarks) onRemarksOpen();
    else onRemarksClose();
  }, [activeRemarks]);

  useEffect(() => {
    if (activeRejectReason) onRejectReasonOpen();
    else onRejectReasonClose();
  }, [activeRejectReason]);

  const handleKycInfoClick = (kyc) => {
    setActiveDoc(kyc);
  };

  const onKycDocActionClick = ({ kyc, newStatus }) => {
    setStatusUpdateData({
      userId,
      status: newStatus,
      kycDetailsId: kyc.id,
      kycType: kyc.kycType,
      remarks: kyc.remarks || '',
      subDocumentType: kyc.subDocumentType,
      rejectionReason: kyc?.rejectionReason || '',
      isReject: KYC_DOC_STATUS.REJECTED === newStatus,
      provider
    });
  };

  const handleRemarksClick = (kyc) => {
    setActiveRemarks(kyc);
  };

  const handleRemarksClose = () => {
    setActiveRemarks(null);
  };

  const handleKycInfoClose = () => {
    setActiveDoc(null);
  };

  const handleUpdateKycClose = () => {
    setStatusUpdateData(null);
  };

  const handleRejectReasonClick = (kyc) => {
    setActiveRejectReason(kyc);
  };

  const handleActiveRejectReasonClose = () => {
    setActiveRejectReason(null);
  };

  const triggerGetKycDocs = useCallback(() => {
    dispatch(
      getKycDocs({
        userId,
        product,
        provider,
        ...searchReqData
      })
    );
  }, [dispatch, userId, product, provider]);

  const triggerGetUserKyc = useCallback(() => {
    dispatch(
      getUserKyc({
        userId,
        product,
        provider
      })
    );
  }, [dispatch, userId, product, provider]);

  const triggerGetUserDetails = useCallback(
    (userId) => {
      dispatch(getUserById(userId));
    },
    [dispatch]
  );

  const triggerSyncKyc = useCallback(
    ({ userId, product, provider }) => {
      setIsSyncLoading(true);
      dispatch(
        syncUserKyc({
          userId,
          product,
          provider,
          onFinish: () => setIsSyncLoading(false)
        })
      );
    },
    [dispatch]
  );

  const handleSyncButtonClick = useCallback(() => {
    triggerSyncKyc({
      userId,
      product: kycDetails.product,
      provider: kycDetails.provider
    });
  }, [userId, kycDetails, triggerSyncKyc]);

  const onKycDocUpdateSuccess = useCallback(
    ({ status }) => {
      triggerGetKycDocs(searchReqData);
      triggerGetUserKyc(kycSearchReqData);
      dispatch(clearAllKycs()); //clear kycs list page since data is now outdated
      dispatch(
        setAlert({
          title: 'Kyc Doc Status updated to ' + status,
          status: 'success'
        })
      );
    },
    [
      dispatch,
      searchReqData,
      kycSearchReqData,
      triggerGetKycDocs,
      triggerGetUserKyc
    ]
  );

  const onCreateEKycSuccess = useCallback(() => {
    triggerGetKycDocs(searchReqData);
    triggerGetUserKyc(kycSearchReqData);
  }, [kycSearchReqData, searchReqData, triggerGetKycDocs, triggerGetUserKyc]);

  const onCreateMKycSuccess = useCallback(() => {
    triggerGetKycDocs(searchReqData);
    triggerGetUserKyc(kycSearchReqData);
  }, [kycSearchReqData, searchReqData, triggerGetKycDocs, triggerGetUserKyc]);

  const handlePageChange = useCallback(
    (pageNo) => {
      pageNo - 1 !== searchReqData.pageNo &&
        dispatch(updateKycDocsReqData({ pageNo: pageNo - 1 }));
    },
    [searchReqData.pageNo, dispatch]
  );

  const getKycInfoStr = useCallback(
    (kycInfo) =>
      kycInfo
        ?.replaceAll(`'`, `\"`)
        .replaceAll('True', 'true')
        .replaceAll('False', 'false'),
    []
  );

  const handleDashboardLinkClick = useCallback(
    () =>
      window.open(
        getProviderDashboardConfigOrNull(provider).url.replace(
          '{clientId}',
          kycDetails?.providerClientId
        )
      ),
    [kycDetails]
  );

  const handleRejectEfrKyc = () => {
    setRjctBtnLoading(true);
    dispatch(
      rejectEfrKyc({
        userId,
        product: PRODUCT.REMITTANCE,
        provider: KYC_PROVIDER.EFR,
        onComplete: () => {
          setRjctBtnLoading(false);
        },
        onSuccess: () => {
          triggerGetUserKyc();
          triggerGetKycDocs();
        }
      })
    );
  };

  const getUserOcrDetails = ({ setPassportFrontOnly = true }) => {
    dispatch(
      getOcrDetails({
        userId,
        isNronboarding: false,
        onComplete: () => {},
        onSuccess: (data) => {
          const { ocrData } = data;
          const { passportFront } = ocrData;
          setPassportFrontOnly && setPassportFrontOcr(passportFront?.ocrData);
          !setPassportFrontOnly && setOcrData(ocrData);
        }
      })
    );
  };

  const handleVerifyClickV2 = ({ provider, payload }) => {
    if (provider === KYC_PROVIDER.ENCRYPTUS) {
      handleVerifyModalOpen(provider);
      return;
    }

    if (provider === KYC_PROVIDER.SABER) {
      handleVerifyModalOpen(provider);
      return;
    }

    if (
      provider === KYC_PROVIDER.ON_RAMP_MONEY &&
      payload?.kyc?.kycType === 'ON_RAMP_KYC_DATA'
    ) {
      handleVerifyModalOpen(provider);
      return;
    }

    onKycDocActionClick(payload);
  };

  const handleVerifyModalOpen = (kycProvider) => {
    switch (kycProvider) {
      case KYC_PROVIDER.ON_RAMP_MONEY:
        onRampMoneyVerifyOpen();
        return;
      case KYC_PROVIDER.SABER:
        onSaberVerifyOpen();
        return;
      case KYC_PROVIDER.ENCRYPTUS:
        onEncryptusVerifyOpen();
        return;
      default:
        return;
    }
  };

  const handleVerifyModalClose = (kycProvider) => {
    switch (kycProvider) {
      case KYC_PROVIDER.ENCRYPTUS:
        onEncryptusVerifyClose();
        return;
      case KYC_PROVIDER.ON_RAMP_MONEY:
        onRampMoneyVerifyClose();
      case KYC_PROVIDER.SABER:
        onSaberVerifyClose();
      default:
        return;
    }
  };

  const handleUpdateOcrSubmit = (data) => {
    const payload = {
      ocrData: {
        ...data
      }
    };
    setORKycBtnLoading(true);
    dispatch(
      updateOcrByDoc({
        userId,
        payload,
        onSuccess: () => {
          triggerGetKycDocs(searchReqData);
          handleVerifyModalClose(provider);
        },
        onComplete: () => {
          setORKycBtnLoading(false);
        }
      })
    );
  };

  const handleTriggerOCRClick = () => {
    dispatch(
      setAlert({
        title: 'Verification success',
        status: 'success'
      })
    );
    handleVerifyModalClose(provider);
    triggerGetKycDocs(searchReqData);
  };

  const handleVerifyAllDocsSubmit = (docs) => {
    setIsVerifyAllDocsLoading(true);
    dispatch(
      updateUserKycDetailsList({
        userId,
        details: docs,
        onSuccess: () => {
          triggerGetKycDocs(searchReqData);
          onVerifyAllDocsClose();
        },
        onComplete: () => {
          setIsVerifyAllDocsLoading(false);
        }
      })
    );
  };

  const showVerifyDocsBtn = (docs) => {
    if (!docs) return false;
    if (docs.length === 0) return false;
    const docsSentToProvider = docs?.filter(
      (doc) => doc.status === 'SENT_TO_PROVIDER'
    );
    if (docsSentToProvider.length > 0) return false;
    const nonTerminalStateDocs = docs?.filter(
      (doc) => !['VERIFIED', 'COMPLETED'].includes(doc.status)
    );
    if (nonTerminalStateDocs.length === 0) return false;
    return true;
  };

  return (
    <Box position={'relative'}>
      <ModalLayout
        isOpen={isEncryptusVerifyOpen}
        onClose={() => handleVerifyModalClose(provider)}
      >
        <ReKycOcrModal
          kycProvider={KYC_PROVIDER.ENCRYPTUS}
          ocrData={ocrData}
          doc={'PASSPORT_FRONT'}
          onCancel={() => handleVerifyModalClose(provider)}
          isLoading={isORKycBtnLoading}
          onSubmit={handleUpdateOcrSubmit}
          onTriggerOcr={handleTriggerOCRClick}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isOnRampMoneyVerifyOpen}
        onClose={() => handleVerifyModalClose(provider)}
      >
        <ReKycOcrModal
          kycProvider={KYC_PROVIDER.ON_RAMP_MONEY}
          ocrData={ocrData}
          doc={'PASSPORT_FRONT'}
          onCancel={() => handleVerifyModalClose(provider)}
          onSubmit={handleUpdateOcrSubmit}
          isLoading={isORKycBtnLoading}
          onTriggerOcr={handleTriggerOCRClick}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isSaberVerifyOpen}
        onClose={() => handleVerifyModalClose(provider)}
      >
        <ReKycOcrModal
          ocrData={ocrData}
          kycProvider={KYC_PROVIDER.SABER}
          doc={'PASSPORT_FRONT'}
          onCancel={() => handleVerifyModalClose(provider)}
          onSubmit={handleUpdateOcrSubmit}
          isLoading={isORKycBtnLoading}
          onTriggerOcr={handleTriggerOCRClick}
        />
      </ModalLayout>
      {provider === KYC_PROVIDER.ON_RAMP_MONEY && (
        <ModalLayout
          size="5xl"
          isOpen={isVerifyAllDocsOpen}
          onClose={onVerifyAllDocsClose}
        >
          <VerifyAllDocsModal
            isLoading={isVerifyAllDocsLoading}
            onCancel={onVerifyAllDocsClose}
            onSubmit={handleVerifyAllDocsSubmit}
            docs={docs?.filter(
              (doc) =>
                doc?.kycType === 'PROOF_OF_IDENTITY' &&
                !['VERIFIED', 'REJECTED'].includes(doc.status)
            )}
          />
        </ModalLayout>
      )}
      {activeDoc && (
        <ModalLayout
          isOpen={isKycInfoOpen}
          onClose={handleKycInfoClose}
          size={'lg'}
        >
          <JsonViewer
            title={'KYC Info'}
            subtitle={activeDoc?.kycType}
            jsonString={getKycInfoStr(activeDoc?.kycInfo)}
          />
        </ModalLayout>
      )}
      {statusUpdateData && (
        <ModalLayout
          isOpen={isUpdateKycStatusOpen}
          onClose={handleUpdateKycClose}
        >
          <KycUpdateModal
            onClose={handleUpdateKycClose}
            statusUpdateData={statusUpdateData}
            onUpdateSuccess={onKycDocUpdateSuccess}
          />
        </ModalLayout>
      )}
      {activeRemarks && (
        <ModalLayout
          isOpen={isRemarksOpen}
          onClose={handleRemarksClose}
          size={'lg'}
        >
          <JsonViewer
            title={'KYC remarks'}
            subtitle={activeRemarks?.kycType}
            jsonString={activeRemarks?.remarks}
          />
        </ModalLayout>
      )}
      {activeRejectReason && (
        <ModalLayout
          isOpen={isRejectReasonOpen}
          onClose={handleActiveRejectReasonClose}
          size="lg"
        >
          <JsonViewer
            title={'KYC Reject reason'}
            jsonString={activeRejectReason?.rejectionReason}
          />
        </ModalLayout>
      )}
      <ContentLayout>
        <Grid gridTemplateColumns={{ base: '1fr', lg: '7fr 0fr 2fr' }}>
          <VStack
            p={10}
            gap={4}
            overflowY={{ base: 'initial', lg: 'scroll' }}
            overflowX={'hidden'}
            h={{ lg: '90vh' }}
            px={{ base: 4, sm: 10 }}
            alignItems={'stretch'}
            className="scroll"
          >
            <VStack w={'100%'} gap={1} alignItems={'flex-start'}>
              <Flex
                w={'100%'}
                alignItems={'baseline'}
                justifyContent={'space-between'}
              >
                <Heading color={'colorPrimary'}>
                  {getVerificationTitle(product, provider)} Details
                </Heading>
              </Flex>
              <Grid
                gridTemplateColumns={{ base: '1fr', lg: '2fr 1.4fr 1fr' }}
                alignItems={'baseline'}
                w={'100%'}
                gap={2}
                width={'100%'}
                maxW={'800px'}
                justifyContent={'space-between'}
              >
                <VStack
                  width={'full'}
                  justifySelf={'flex-start'}
                  alignItems={'stretch'}
                >
                  <HStack>
                    <Text color={'gray'}>Status : </Text>
                    <HStack>
                      {kycDetails?.kycStatus && (
                        <Icon
                          viewBox="0 0 200 200"
                          color={getKycV2StatusColor(kycDetails?.kycStatus)}
                          w="8px"
                        >
                          <path
                            fill="currentColor"
                            d="M 100, 100 m -75, 0 a 75,75 0 1,0 150,0 a 75,75 0 1,0 -150,0"
                          />
                        </Icon>
                      )}
                      <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
                        {kycDetails?.kycStatus || '-'}
                      </Text>
                      {isAllowedKycProviderForSync(provider) &&
                        (!isSyncLoading ? (
                          <AiOutlineSync
                            onClick={handleSyncButtonClick}
                            color={'white'}
                            cursor={'pointer'}
                          />
                        ) : (
                          <Spinner color={'white'} size={'sm'} />
                        ))}
                    </HStack>
                  </HStack>
                  <HStack>
                    <Text color={'gray'}>Client ID : </Text>
                    <HStack>
                      <CopyableText
                        fontSize={{ base: 'sm', sm: 'md' }}
                        color="white"
                      >
                        {kycDetails?.providerClientId || '-'}
                      </CopyableText>
                    </HStack>
                  </HStack>
                </VStack>
                {getProviderDashboardConfigOrNull(provider) !== null && (
                  <>
                    <HStack
                      color={'white'}
                      cursor={'pointer'}
                      _hover={{
                        textDecor: 'underline'
                      }}
                      onClick={handleDashboardLinkClick}
                    >
                      <Text>
                        {getProviderDashboardConfigOrNull(provider).text}
                      </Text>
                      <RxDashboard display={'inline'} />
                    </HStack>
                    <Link
                      as={ReactLink}
                      to={`/user/${userId}/kyc/checks`}
                      color="white"
                      justifyContent={'flex-start'}
                      justifySelf={'flex-end'}
                    >
                      <HStack>
                        <Text>View Checks</Text>
                        <BsArrowRight display={'inline'} />
                      </HStack>
                    </Link>
                  </>
                )}
                {provider === KYC_PROVIDER.EFR &&
                  kycDetails?.kycStatus !== KYC_STATUS.REJECTED &&
                  hasAnyAuthority(ROLES.ADMIN_ROLE, ROLES.MANAGER_ROLE) && (
                    <>
                      <Box></Box>
                      <HStack width={'full'} justifyContent={'end'}>
                        <Button
                          onClick={handleRejectEfrKyc}
                          variant={'outline'}
                          colorScheme="red"
                          isLoading={rjctBtnLoading}
                          disabled={isLoading}
                        >
                          Reject KYC
                        </Button>
                      </HStack>
                    </>
                  )}
              </Grid>
              {provider === KYC_PROVIDER.ON_RAMP_MONEY &&
                !(
                  kycDetails?.kycStatus === KYC_STATUS.REJECTED ||
                  kycDetails?.kycStatus === KYC_V2_STATUS.VERIFIED
                ) &&
                showVerifyDocsBtn(
                  docs
                    ? docs.filter((doc) => doc.kycType === 'PROOF_OF_IDENTITY')
                    : null
                ) && (
                  <>
                    <HStack width={'full'} justifyContent={'end'}>
                      <Button
                        colorScheme="brand"
                        onClick={() => {
                          onVerifyAllDocsOpen();
                        }}
                        variant={'outline'}
                      >
                        Verify Docs
                      </Button>
                    </HStack>
                  </>
                )}
            </VStack>

            {totalPages > 0 && (
              <Box alignSelf={'start'}>
                <Pagination
                  totalPages={totalPages}
                  currentPage={searchReqData.pageNo + 1}
                  onPageChange={handlePageChange}
                />
              </Box>
            )}
            {isLoading && <Spinner size={'lg'} color={'brand.200'} />}
            <VStack
              alignSelf={'flex-start'}
              w={'100%'}
              alignItems={'flex-start'}
              gap={1}
            >
              {docs?.length > 0 &&
                docs.map((item) => (
                  <KycDocCard
                    kycProvider={provider}
                    doc={item}
                    key={item.id}
                    onKycDocActionClick={onKycDocActionClick}
                    onKycInfoClick={handleKycInfoClick}
                    userId={userId}
                    onRemarksClick={handleRemarksClick}
                    onRejectReasonClick={handleRejectReasonClick}
                    onVerifyClickV2={handleVerifyClickV2}
                  />
                ))}
            </VStack>
          </VStack>
          <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          ></Divider>
          {!isMobile && (
            <Box m={0} alignSelf={'flex-start'}>
              {
                <UserSideCard
                  userDetails={userDetails}
                  onCreateEKycSuccess={onCreateEKycSuccess}
                  onCreateMKycSuccess={onCreateMKycSuccess}
                  showKycActions={provider === KYC_PROVIDER.COMPLY_CUBE}
                />
              }
            </Box>
          )}
        </Grid>
      </ContentLayout>
    </Box>
  );
};

export default KycDocs;
