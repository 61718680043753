import {
  Button,
  Grid,
  HStack,
  Stack,
  StackDivider,
  Text,
  VStack,
  Link,
  IconButton
} from '@chakra-ui/react';
import { Link as ReactLink, useNavigate } from 'react-router-dom';
import { MdOutlineOpenInNew } from 'react-icons/md';
import React from 'react';
import { BsFillFlagFill, BsPlusCircle } from 'react-icons/bs';

const ThunesFulfillmentCard = ({
  fulfillmentData,
  isSuperAdmin,
  onForceInitiateClick,
  // onOnHoldReasonClick,
  onRecentTransfersClick,
  onChecksClick,
  onFlagButtonClick
}) => {
  return (
    <VStack
      alignSelf={'flex-start'}
      w={{ base: '100%', md: '100%' }}
      maxW={'850px'}
      bg="backGround"
      borderRadius="12px"
      position="relative"
      divider={
        <StackDivider style={{ margin: '0' }} borderColor="whiteAlpha.300" />
      }
    >
      {fulfillmentData?.orderMetadata?.complianceFlagged ? (
        <IconButton
          position={'absolute'}
          rounded={'full'}
          top={-2}
          right={-2}
          bg={'altGray.500'}
          color={'red.500'}
          _hover={{
            background: 'red.500',
            color: 'altGray.500'
          }}
          size={'sm'}
          fontSize={'0.9rem'}
          fontWeight={400}
          icon={<BsFillFlagFill />}
          onClick={onFlagButtonClick}
        />
      ) : (
        <IconButton
          position={'absolute'}
          rounded={'full'}
          top={-2}
          right={-2}
          bg={'altGray.500'}
          color={'brand.200'}
          _hover={{
            background: 'brand.200',
            color: 'altGray.500'
          }}
          size={'sm'}
          fontSize={'0.9rem'}
          fontWeight={400}
          icon={<BsPlusCircle />}
          onClick={onFlagButtonClick}
        />
      )}
      <Grid
        gridTemplateColumns={'repeat(2,auto)'}
        rowGap={2}
        w={'90%'}
        justifyContent="space-between"
        m={'1rem'}
      >
        <HStack alignItems={'flex-start'}>
          <Text fontSize={{ base: 'sm', sm: 'md' }} color="gray">
            Order Id:
          </Text>
          <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
            {fulfillmentData.orderId}
          </Text>
          {fulfillmentData.isBoldPitchUser === true && (
            <Text fontSize={{ base: 'sm', sm: 'md' }} color="white">
              (Bold Pitch)
            </Text>
          )}
        </HStack>
      </Grid>
      <Grid
        gridTemplateColumns={{
          base: '1fr',
          md: `repeat(3,1fr) 0.8fr 135px ${isSuperAdmin ? '135px' : ''}`
        }}
        gap={2}
        alignItems={'flex-start'}
        w={'90%'}
        m={'1rem'}
        textAlign={{ base: 'end', md: 'start' }}
      >
        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            User
          </Text>
          <Link
            as={ReactLink}
            to={`/user/${fulfillmentData.userId}`}
            color="white"
            fontSize={'sm'}
          >
            <HStack pt={2}>
              <Text>{fulfillmentData.userName}</Text>
            </HStack>
          </Link>
        </Stack>

        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            Source
          </Text>
          <Link
            as={ReactLink}
            to={`/user/${fulfillmentData.userId}`}
            color="white"
            fontSize={'sm'}
          >
            <HStack pt={2}>
              <Text>{fulfillmentData.sourceAccountName || '-'}</Text>
            </HStack>
          </Link>
        </Stack>

        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            Beneficiary
          </Text>
          <Link
            as={ReactLink}
            to={`/user/${fulfillmentData.userId}`}
            color="white"
            fontSize={'sm'}
          >
            <HStack pt={2}>
              <Text>{fulfillmentData.beneficiaryName || '-'}</Text>
            </HStack>
          </Link>
        </Stack>

        {/* <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            Reason
          </Text>
          {fulfillmentData.onHoldReason?.length ? (
            <MdOutlineOpenInNew
              color={'gray'}
              cursor={'pointer'}
              onClick={() =>
                onOnHoldReasonClick({
                  fulfillmentId: fulfillmentData.fulfillmentId,
                  onHoldReason: fulfillmentData.onHoldReason
                })
              }
            />
          ) : (
            <Text fontSize={'sm'} color={'white'}>
              -
            </Text>
          )}
        </Stack> */}

        <Stack
          direction={{ base: 'row', md: 'column' }}
          justifyContent={'space-between'}
          height={'100%'}
        >
          <Text fontSize={'sm'} color={'gray'}>
            Checks
          </Text>
          <MdOutlineOpenInNew
            color={'gray'}
            cursor={'pointer'}
            onClick={() => {
              onChecksClick({
                orderId: fulfillmentData?.orderId
              });
            }}
          />
        </Stack>
        <Button
          size={'sm'}
          mt={{ base: 2, md: 0 }}
          ml={{ base: 0, md: 1 }}
          // rightIcon={<GrTrigger color="black" />}
          type="submit"
          rounded="full"
          color={'black'}
          colorScheme="brand"
          alignSelf={{ base: 'center', md: 'center' }}
          px={5}
          onClick={() =>
            onRecentTransfersClick({
              userId: fulfillmentData.userId,
              orderId: fulfillmentData.orderId
            })
          }
        >
          Recent Transfers
        </Button>

        {isSuperAdmin && (
          <Button
            size={'sm'}
            mt={{ base: 2, md: 0 }}
            ml={{ base: 0, md: 1 }}
            // rightIcon={<GrTrigger color="black" />}
            type="submit"
            rounded="full"
            color={'black'}
            colorScheme="brand"
            alignSelf={{ base: 'center', md: 'center' }}
            px={5}
            onClick={() => {
              onForceInitiateClick({
                fulfillmentId: fulfillmentData.fulfillmentId
              });
            }}
          >
            Force Initiate
          </Button>
        )}
      </Grid>
    </VStack>
  );
};

export default ThunesFulfillmentCard;
