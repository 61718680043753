import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getOnboardingStatus,
  updateOnboardingStatus
} from '../../store/actions/nreNroActions';
import {
  Box,
  HStack,
  useDisclosure,
  Text,
  IconButton,
  Stack
} from '@chakra-ui/react';
import ModalLayout from '../../components/Modal/ModalLayout';
import GenericSelectModal from '../../components/Modal/GenericSelectModal';
import { NR_ONBOARDING_STATUS, ROLES } from '../../constants';
import { AiFillEdit } from 'react-icons/ai';
import useAuthRole from '../../hooks/useAuthRole';
import TriggerApisBar from './TriggerApisBar';

const NrOnboarding = ({ userId }) => {
  const { hasAnyAuthority } = useAuthRole();
  const { data: statusData, isLoading } = useSelector(
    (state) => state.nreNro.onboarding.status
  );
  const [isUpdateStatusLoading, setIsUpdateStatusLoading] = useState(false);
  const dispatch = useDispatch();

  const {
    isOpen: isUpdateStatusOpen,
    onOpen: onUpdateStatusOpen,
    onClose: onUpdateStatusClose
  } = useDisclosure();

  const triggerGetNrOnboardingStatus = (userId) => {
    dispatch(getOnboardingStatus(userId));
  };

  const triggerUpdateNrOnboardingStatus = ({ referenceId, value }) => {
    setIsUpdateStatusLoading(true);
    dispatch(
      updateOnboardingStatus({
        userId: referenceId,
        status: value,
        onSuccess: () => {},
        onFinish: () => {
          setIsUpdateStatusLoading(false);
          onUpdateStatusClose();
        }
      })
    );
  };

  useEffect(() => {
    triggerGetNrOnboardingStatus({ userId });
  }, [userId]);

  return (
    <Box position={'relative'}>
      {!isLoading && (
        <ModalLayout
          isOpen={isUpdateStatusOpen}
          onClose={onUpdateStatusClose}
          size={'md'}
        >
          <GenericSelectModal
            referenceId={userId}
            options={NR_ONBOARDING_STATUS}
            initialValue={statusData?.onboardingStatus || ''}
            placeholder={'Enter new status'}
            title={'Update Onboarding Status'}
            label={'Status: '}
            subtitle={`For user ${userId}`}
            handleSubmit={triggerUpdateNrOnboardingStatus}
            isLoading={isUpdateStatusLoading}
          />
        </ModalLayout>
      )}
      <Stack w={'100%'}>
        <HStack color={'colorPrimary'}>
          <Text
            color={'shadedGray.500'}
            fontSize={{ base: '0.75rem', md: '0.9rem' }}
          >
            STATUS :
          </Text>
          <Text fontSize={{ base: '0.75rem', md: '0.9rem' }}>
            {statusData?.onboardingStatus || ''}
          </Text>
          {hasAnyAuthority(ROLES.ADMIN_ROLE, ROLES.YBL_RDA_MANAGER) && (
            <IconButton
              rounded={'full'}
              color={'altGray.400'}
              bg={'brand.200'}
              _hover={{
                color: 'brand.200',
                background: 'altGray.400'
              }}
              size={'sm'}
              fontWeight={400}
              icon={<AiFillEdit />}
              onClick={onUpdateStatusOpen}
            />
          )}
        </HStack>
        {statusData?.onboardingStatus &&
          statusData?.onboardingStatus !== 'NEW' &&
          statusData?.onboardingStatus !== 'FILLING_FORM' &&
          hasAnyAuthority(ROLES.ADMIN_ROLE, ROLES.YBL_RDA_MANAGER) && (
            <TriggerApisBar userId={userId} />
          )}
      </Stack>
    </Box>
  );
};

export default NrOnboarding;
