import { useMemo } from 'react';

export const DOTS = '...';

const range = (start, end) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

export const usePagination = ({
  currentPage,
  totalPages,
  siblingCount = 1
}) => {
  return useMemo(() => {
    if (!currentPage || !totalPages || totalPages < 1) {
      return [];
    }

    const normalizedCurrentPage = Math.max(
      1,
      Math.min(currentPage, totalPages)
    );
    const totalItemsNumber = siblingCount + 5; // Increased for more robust handling

    // If total pages are less than or equal to total items to display
    if (totalItemsNumber >= totalPages) {
      return range(1, totalPages);
    }

    const leftSiblingIndex = Math.max(normalizedCurrentPage - siblingCount, 1);
    const rightSiblingIndex = Math.min(
      normalizedCurrentPage + siblingCount,
      totalPages
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < totalPages - 1;

    // Scenario 1: No left dots, right dots
    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemsCount = 3 + 2 * siblingCount;
      let leftRange = range(1, leftItemsCount);

      return [...leftRange, DOTS, totalPages];
    }

    // Scenario 2: Left dots, no right dots
    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemsCount = 3 + 2 * siblingCount;
      let rightRange = range(totalPages - rightItemsCount + 1, totalPages);
      return [1, DOTS, ...rightRange];
    }

    // Scenario 3: Dots on both sides
    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [1, DOTS, ...middleRange, DOTS, totalPages];
    }

    // Fallback: Default range
    return range(1, totalPages);
  }, [totalPages, currentPage, siblingCount]);
};
