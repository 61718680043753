import React, { useState, useEffect, useCallback } from 'react';
import {
  VStack,
  Heading,
  Text,
  Box,
  Button,
  Spinner,
  useDisclosure,
  IconButton,
  HStack
} from '@chakra-ui/react';
import { MdDeleteOutline } from 'react-icons/md';
import useAuthRole from '../../../hooks/useAuthRole';
import { ROLES } from '../../../constants/index';
import { useDispatch, useSelector } from 'react-redux';
import ModalLayout from '../../Modal/ModalLayout';
import GenericUpdateModal from '../../Modal/GenericUpdateModal';
import {
  getAllHolidays,
  softDeleteHoliday,
  saveHoliday
} from '../../../store/actions/rdaDashboardActions';

const Holiday = ({ countryCode }) => {
  const {
    isOpen: isHolidayModalOpen,
    onClose: onHolidayModalClosed,
    onOpen: onHolidayModalOpen
  } = useDisclosure();

  const dispatch = useDispatch();
  const { holidays, isLoading: isLoadingHolidays } = useSelector(
    (state) => state?.rdaDashboard
  );
  const { hasAnyAuthority } = useAuthRole();

  const holidayList = holidays?.holidayList || [];
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  // Mapping holidays by country code
  const mapCountryCodestoHolidays = () => {
    const tempHolidayMap = new Map();
    holidayList.forEach((holiday) => {
      const { countryCode, holidayId, startDate, reason } = holiday;
      if (!tempHolidayMap.has(countryCode)) {
        tempHolidayMap.set(countryCode, [{ holidayId, startDate, reason }]);
      } else {
        tempHolidayMap.get(countryCode).push({ holidayId, startDate, reason });
      }
    });
    return tempHolidayMap;
  };

  // Handle adding a new holiday
  const handleSubmit = useCallback(
    ({ holidayName, startDate, endDate }) => {
      setIsSubmitLoading(true);
      const reqBody = {
        reason: holidayName,
        start_date: startDate,
        end_date: startDate,
        country_codes: [countryCode]
      };

      dispatch(
        saveHoliday({
          reqBody,
          onSuccess: () => {
            dispatch(getAllHolidays());
          }
        })
      ); // No await here
      setIsSubmitLoading(false);
      onHolidayModalClosed();
    },
    [countryCode, dispatch, onHolidayModalClosed]
  );

  // Handle deleting a holiday
  const handleDelete = useCallback(
    (id) => {
      setIsDeleteLoading(true);
      dispatch(
        softDeleteHoliday({
          id,
          onSuccess: () => {
            dispatch(getAllHolidays());
          }
        })
      );
      setIsDeleteLoading(false);
    },
    [dispatch]
  );

  // Map country code to country name
  const getCountryNameFromCode = (code) => {
    const countries = {
      IN: 'India',
      GB: 'United Kingdom'
    };
    return countries[code] || `***unknown country code - ${code}***`;
  };
  useEffect(() => {
    dispatch(getAllHolidays());
  }, []);
  return (
    <VStack
      align="stretch"
      spacing={6}
      bg="gray.800"
      p={6}
      borderRadius="md"
      shadow="md"
      width="350px"
    >
      {/* Modal for creating/updating holidays */}
      <ModalLayout isOpen={isHolidayModalOpen} onClose={onHolidayModalClosed}>
        <GenericUpdateModal
          title="Create Holiday"
          data={{
            holidayName: 'Enter Holiday',
            startDate: 'YYYY-MM-DD',
            countryCode: countryCode
          }}
          onSubmit={handleSubmit}
          onCancel={onHolidayModalClosed}
          isLoading={isSubmitLoading}
        />
      </ModalLayout>

      <Heading size="md" color="white">
        Manage {getCountryNameFromCode(countryCode)} Holidays
      </Heading>

      {/* Loading state */}
      {isSubmitLoading && isLoadingHolidays ? (
        <VStack align="center" justify="center" minH="200px">
          <Spinner size="lg" color="blue.400" />
          <Text color="gray.400" mt={4}>
            Fetching holidays, please wait...
          </Text>
        </VStack>
      ) : holidayList.length === 0 ? (
        <Text color="gray.400" mt={4}>
          No holidays available. Add a new holiday to get started.
        </Text>
      ) : (
        <VStack align="stretch" spacing={4}>
          {Array.from(mapCountryCodestoHolidays()).map(
            ([code, holidays], index) => (
              <Box
                key={index}
                p={4}
                bg="gray.700"
                borderRadius="md"
                shadow="sm"
                display="flex"
                flexDirection="column"
                gap={4}
              >
                {countryCode === code &&
                  holidays.map((holiday) => {
                    const { holidayId, startDate, reason } = holiday;

                    return (
                      <Box
                        key={holidayId}
                        bg="gray.800"
                        p={3}
                        borderRadius="sm"
                        shadow="xs"
                      >
                        <HStack
                          alignItems="center"
                          justifyContent="space-between"
                          spacing={4}
                          bg="gray.800"
                          p={3}
                          borderRadius="md"
                          shadow="xs"
                        >
                          <VStack alignItems="start" spacing={2} flex="1">
                            <Text color="white" fontWeight="bold">
                              {new Date(startDate).toLocaleDateString('en-US')}
                            </Text>
                            <Text color="gray.300">{reason}</Text>
                          </VStack>

                          <Box color="white" fontSize="lg">
                            {hasAnyAuthority(ROLES.YBL_RDA_MANAGER) && (
                              <IconButton
                                icon={<MdDeleteOutline />}
                                aria-label={`Delete holiday ${reason}`}
                                colorScheme="red"
                                onClick={() => handleDelete(holidayId)}
                                size="md"
                                isLoading={isDeleteLoading}
                                _hover={{ bg: 'red.600', color: 'white' }}
                                _active={{ bg: 'red.700' }}
                              />
                            )}
                          </Box>
                        </HStack>
                      </Box>
                    );
                  })}
              </Box>
            )
          )}
        </VStack>
      )}

      {/* Button to add holiday */}
      {hasAnyAuthority(ROLES.YBL_RDA_MANAGER) && (
        <Button
          colorScheme="blue"
          onClick={onHolidayModalOpen}
          alignSelf="flex-start"
        >
          Add holiday
        </Button>
      )}
    </VStack>
  );
};

export default Holiday;
