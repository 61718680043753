import RDA from '../constants/rdaConstants';

const initialState = {
  isError: false,
  isLoading: false,
  error: null,
  data: [],
  GbpInrBalanace: 0
};

const rdaReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case RDA.CREATE_POT_SUCCESS:
      return {
        ...state,
        data: [...state?.data, payload]
      };
    case RDA.GETTING_ALL_POTS:
      return {
        ...state,
        isLoading: true,
        isError: false,
        error: null,
        // totalPages: 0,
        data: []
      };
    case RDA.GET_ALL_POTS_SUCCESS:
      return {
        ...state,
        data: payload?.data,
        isLoading: false,
        isError: false,
        error: null
        // totalPages: payload?.totalPagess
      };

    case RDA.DELETE_POT_SUCCESS:
      return state;

    case RDA.UPDATE_ACTIVE_ONLY:
      return {
        ...state,
        activeOnly: !state?.activeOnly
      };

    case RDA.GETTING_RDA_TRANSACTIONS:
      return { ...state, loading: true, error: null };
    case RDA.GET_RDA_TRANSACTIONS_SUCCESS:
      return { ...state, loading: false, transactions: action.payload };
    case RDA.GET_RDA_TRANSACTIONS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case RDA.GETTING_RDA_TRANSACTION_DETAILS:
      return { ...state, loading: true, error: null };
    case RDA.GET_RDA_TRANSACTION_DETAILS_SUCCESS:
      return { ...state, loading: false, transactionDetails: action.payload };
    case RDA.GET_RDA_TRANSACTION_DETAILS_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case RDA.GET_GBP_INR:
      return { ...state, loading: true };
    case RDA.GETTING_GBP_INR_BALANCE_SUCCESS:
      return { ...state, loading: false, error: action.payload };
    case RDA.GETTING_GBP_INR_BALANCE_FAILURE:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default rdaReducer;
