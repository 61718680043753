import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Text,
  Box,
  Spinner,
  Stack,
  Input,
  IconButton,
  VStack,
  Heading,
  HStack,
  Flex,
  Button,
  Grid,
  StackDivider
} from '@chakra-ui/react';
import { FaSearch } from 'react-icons/fa';
import Pagination from '../../../components/Pagination';
import {
  getTransactions,
  getTransactionDetails
} from '../../../store/actions/falconActions';
import FalconTransactionCard from '../../../components/FalconTransactionCard/FalconTransactionCard';
import useDebounce from '../../../hooks/useDebounce';
import ContentLayout from '../../../layouts/ContentLayout';
import GenericCard from '../../../components/GenericCard';
import { CARD_TYPE } from '../../../constants';
import OrderDetailsCard from '../../../components/OrderDetailsCard';
import OrderCard from '../../../components/OrderCard';

const FalconSearchPage = () => {
  const dispatch = useDispatch();
  const [textInput, setTextInput] = useState('');
  const debouncedValue = useDebounce(textInput, 500);

  const {
    falconTransactionsList,
    currentPage,
    totalPages,
    totalResults,
    error,
    isLoading,
    isLoadingDetails,
    transaction,
    reqBody
  } = useSelector((state) => ({
    falconTransactionsList:
      state.falconOps.transactionsData.falconTransactionsList,
    currentPage: state.falconOps.transactionsData.currentPage,
    totalPages: state.falconOps.transactionsData.totalPages,
    totalResults: state.falconOps.transactionsData.totalResults,
    error: state.falconOps.transactionsData.error,
    isLoading: state.falconOps.isLoading,
    isLoadingDetails: state.falconOps.isLoadingDetails,
    transaction: state.falconOps.transaction,
    reqBody: state.falconOps.reqBody
  }));

  const itemsPerPage = reqBody?.itemsPerPage || 10;

  const fetchTransactions = (pageNumber = 1) => {
    dispatch(
      getTransactions({
        reqBody: {
          ...reqBody,
          search_key: 'transactionId',
          search_value: debouncedValue || null
        },
        pageNumber
      })
    );
  };

  const handleSearchChange = (event) => setTextInput(event.target.value);

  const handlePageChange = (page) => {
    const pageNumber = Math.max(0, page - 1);
    fetchTransactions(pageNumber);
  };
  const handleOrderDetailsClick = (transactionId) => {
    dispatch(getTransactionDetails({ transactionId: transactionId }));
  };
  useEffect(() => {
    fetchTransactions(currentPage);
  }, [debouncedValue, currentPage]);

  return (
    <ContentLayout>
      <Grid gridTemplateColumns={{ base: '1fr', lg: '4fr 2fr' }}>
        <VStack
          overflowY={{ base: 'initial', lg: 'auto' }}
          overflowX={'hidden'}
          h={{ lg: '90vh' }}
          py={10}
          px={{ base: 4, sm: 10 }}
          alignItems={'stretch'}
          className="scroll"
          divider={
            <StackDivider
              style={{ margin: '0' }}
              borderColor="whiteAlpha.300"
            />
          }
          gap={2}
        >
          <Heading as="h1" size="lg" color="brand.500" fontWeight="bold">
            Falcon Transactions
          </Heading>

          {/* Search Bar */}
          <HStack width="full" spacing={3}>
            <Input
              placeholder="Search transactions..."
              value={textInput}
              onChange={handleSearchChange}
              size="lg"
              width="100%"
              bg="altGray.600"
              color="white"
              focusBorderColor="colorPrimary"
              _placeholder={{ color: 'shadedGray.500' }}
              rounded="md"
            />
            <IconButton
              icon={<FaSearch />}
              onClick={() => fetchTransactions(currentPage)}
              colorScheme="brand"
              variant="solid"
              bg="colorPrimary"
              color="altGray.600"
              aria-label="Search Transactions"
              size="lg"
              _hover={{ bg: 'brand.400' }}
              rounded="md"
            />
          </HStack>

          {/* Pagination Controls */}
          {totalResults > itemsPerPage && (
            <Box justifyItems="center" width="100%">
              <Pagination
                currentPage={currentPage + 1 || 1}
                totalPages={totalPages}
                onPageChange={handlePageChange}
                siblingCount={5}
              />
            </Box>
          )}

          {/* Loading or Content */}
          {isLoading ? (
            <Flex justify="center" align="center" width="full" pt={5}>
              <Spinner size="xl" color="colorPrimary" />
            </Flex>
          ) : error ? (
            <Box
              color="red.400"
              p={4}
              bg="altGray.400"
              borderRadius="md"
              borderWidth="1px"
            >
              <Text>
                An error occurred while fetching transactions: {error.message}
              </Text>
            </Box>
          ) : falconTransactionsList.length > 0 ? (
            <VStack alignItems="center">
              {falconTransactionsList.map((data) => (
                <FalconTransactionCard
                  key={data.transactionId}
                  falconTransaction={data}
                  onOrderDetailsClick={handleOrderDetailsClick}
                  isSidebar={false}
                />
              ))}
            </VStack>
          ) : (
            <Text>No Transactions Found</Text>
          )}
        </VStack>
        <VStack align="right">
          {Object.keys(transaction).length !== 0 && (
            <Box>
              {!isLoadingDetails ? (
                <FalconTransactionCard
                  falconTransaction={transaction}
                  isSidebar={true}
                />
              ) : (
                <Spinner />
              )}
            </Box>
          )}
        </VStack>
      </Grid>
    </ContentLayout>
  );
};

export default FalconSearchPage;
