export default function falconTransactionsSearch(fetch, baseUrl) {
  return {
    search({ reqBody }) {
      return fetch.post(`${baseUrl}/search`, reqBody);
    },
    getDetails({ transactionId }) {
      return fetch.get(`${baseUrl}/${transactionId}`);
    }
  };
}
