import api from '../../api';
import { getErrorMessage } from '../../utils';
import { THUNES_COLLECTION as types } from '../constants';
import { setAlert } from './alertActions';

export const getThunesOnHoldFulfillmentsV3 = (reqBody) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_THUNES_ON_HOLD_FULFILLMENTS,
      payload: reqBody
    });
    const { data } = await api.thunes.getOnHoldFulfillmentsV3(reqBody);
    return dispatch({
      type: types.GET_THUNES_ON_HOLD_FULFILLMENTS_SUCCESS,
      payload: data
    });
  } catch (error) {
    return dispatch(
      setAlert({
        title: getErrorMessage({
          error,
          genericMessage: 'Failed to get Thunes on hold fulfillments V3'
        }),
        status: 'error'
      })
    );
  }
};

export const getThunesOnHoldFulfillments = (request) => async (dispatch) => {
  try {
    dispatch({
      type: types.GETTING_THUNES_ON_HOLD_FULFILLMENTS,
      payload: request
    });
    const { data } = await api.thunes.getOnHoldFulfillmentsV3(request);
    return dispatch({
      type: types.GET_THUNES_ON_HOLD_FULFILLMENTS_SUCCESS,
      payload: data
    });
  } catch (error) {
    dispatch(
      setAlert({
        title: getErrorMessage({
          error,
          genericMessage: 'Failed to get Thunes on hold fulfillments'
        }),
        status: 'error'
      })
    );
    return dispatch({
      type: types.GET_THUNES_ON_HOLD_FULFILLMENTS_FAILURE,
      payload: error
    });
  }
};

export const setFulfillmentsToInitialState = () => (dispatch) => {
  dispatch({
    type: types.SET_FULFILLMENTS_TO_INITIAL_STATE
  });
};

export const forceInitiateFulfillment =
  ({ fulfillmentId, onSuccess, onFinish }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: types.FORCE_INITIATING_FULFILLMENT
      });
      await api.thunes.forceInitiateFulfillment({ fulfillmentId });
      if (onSuccess) onSuccess();

      dispatch(
        setAlert({
          title: 'Force initiated remittance for order:  ' + fulfillmentId,
          status: 'success'
        })
      );

      return dispatch({
        type: types.FORCE_INITIATE_FULFILLMENT_SUCCESS
      });
    } catch (error) {
      dispatch(
        setAlert({
          title: getErrorMessage({
            error,
            genericMessage: 'Failed to force initiate Thunes fulfillment'
          }),
          status: 'error'
        })
      );
      return dispatch({
        type: types.FORCE_INITIATE_FULFILLMENT_FAILURE,
        payload: error
      });
    } finally {
      if (onFinish) onFinish();
    }
  };

export const updateBeneficiaryRisks =
  ({ orderId, acctNo, riskCategory, onSuccess, onComplete }) =>
  async (dispatch) => {
    try {
      await api.orders.updateBeneficiaryChecks({
        orderId,
        acctNo,
        riskCategory
      });
      onSuccess && onSuccess();
    } catch (error) {
      console.error(error?.message);
      dispatch(
        setAlert({
          title: error?.message,
          status: 'error'
        })
      );
    } finally {
      onComplete && onComplete();
    }
  };
