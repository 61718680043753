import {
  Box,
  Button,
  Grid,
  HStack,
  Stack,
  StackDivider,
  Text,
  VStack
} from '@chakra-ui/react';
import React from 'react';
import { MdEast } from 'react-icons/md';
import CopyableText from '../CopyableText';

const FalconTransactionCard = ({
  onOrderDetailsClick,
  falconTransaction,
  show,
  isSidebar = false
}) => {
  return (
    <Box position="relative">
      <VStack
        bg={!isSidebar ? 'backGround' : undefined}
        borderRadius="12px"
        divider={<StackDivider borderColor="whiteAlpha.300" />}
        p={isSidebar ? 2 : 4}
        align="stretch"
      >
        {isSidebar ? (
          <VStack align="start" spacing={3} width="100%">
            <HStack justifyContent="space-between" width="100%">
              <Text fontSize="sm" color="gray">
                Transaction ID:
              </Text>
              <CopyableText title="Order ID" fontSize="sm" color="white">
                {falconTransaction?.transactionId || '-'}
              </CopyableText>
            </HStack>
            <HStack justifyContent="space-between" width="100%">
              <Text fontSize="sm" color="gray">
                Client Transaction ID:
              </Text>
              <CopyableText title="Order ID" fontSize="sm" color="white">
                {falconTransaction?.clientTxnId || '-'}
              </CopyableText>
            </HStack>
            <HStack justifyContent="space-between" width="100%">
              <Text fontSize="sm" color="gray">
                Expires On:
              </Text>
              <Text fontSize="sm" color="white">
                {new Date(
                  falconTransaction?.transactionExpiresAt
                ).toLocaleString() || '-'}
              </Text>
            </HStack>
            <HStack justifyContent="space-between" width="100%">
              <Text fontSize="sm" color="gray">
                Source Amount:
              </Text>
              <Text fontSize="sm" color="white">
                {`${falconTransaction?.sourceAmount || '-'} ${
                  falconTransaction?.sourceCurrency || '-'
                }`}
              </Text>
            </HStack>
            <HStack justifyContent="space-between" width="100%">
              <Text fontSize="sm" color="gray">
                Payout Amount:
              </Text>
              <Text fontSize="sm" color="white">
                {`${falconTransaction?.payoutAmount || '-'} ${
                  falconTransaction?.payoutCurrency || '-'
                }`}
              </Text>
            </HStack>
            {show && (
              <HStack justifyContent="space-between" width="100%">
                <Text fontSize="sm" color="gray">
                  {show
                    .replace(/([A-Z])/g, ' $1')
                    .split(' ')
                    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ')}
                  :
                </Text>
                <Text fontSize="sm" color="white">
                  {falconTransaction[show] || '-'}
                </Text>
              </HStack>
            )}
            <HStack justifyContent="space-between" width="100%">
              <Text fontSize="sm" color="gray">
                Client:
              </Text>
              <Text fontSize="sm" color="white">
                {falconTransaction?.client || '-'}
              </Text>
            </HStack>
            <HStack justifyContent="space-between" width="100%">
              <Text fontSize="sm" color="gray">
                Exchange Rate:
              </Text>
              <Text fontSize="sm" color="white">
                {falconTransaction?.exchangeRate || '-'}
              </Text>
            </HStack>
            {falconTransaction?.offRampData?.length > 0 ? (
              <VStack align="stretch" spacing={3}>
                <Text fontSize="md" color="gray" fontWeight="bold">
                  Off Ramp Data ({falconTransaction.offRampData.length})
                </Text>
                {falconTransaction?.offRampData.map((data, index) => (
                  <VStack
                    key={index}
                    align="start"
                    spacing={2}
                    width={'100%'}
                    p={3}
                    borderRadius="8px"
                  >
                    <Text fontSize="sm" color="white">
                      <b>{`Entry ${index + 1}`}</b>
                    </Text>
                    {[
                      ['Txn ID:', data?.txnId || '-'],
                      ['Txn Hash:', data?.txnHash || '-'],
                      ['Status:', data?.status || '-'],
                      [
                        'Receive Amount:',
                        data?.receiveAmount
                          ? `${data.receiveAmount} ${data.cryptoCurrency}`
                          : '-'
                      ],
                      ['Crypto Amount:', data?.cryptoAmount || '-'],
                      ['Payout ID:', data?.payoutId || '-'],
                      ['Payout Status:', data?.payoutStatus || '-'],
                      ['Transfer Type:', data?.transferType || '-'],
                      [
                        'Created At:',
                        data?.createdAt
                          ? new Date(data.createdAt).toLocaleString()
                          : '-'
                      ],
                      [
                        'Updated At:',
                        data?.updatedAt
                          ? new Date(data.updatedAt).toLocaleString()
                          : '-'
                      ]
                    ].map(([label, value], idx) => (
                      <HStack
                        key={idx}
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Text fontSize="sm" color="gray.400">
                          {label}
                        </Text>
                        <Text fontSize="sm" color="white">
                          {value}
                        </Text>
                      </HStack>
                    ))}
                  </VStack>
                ))}
              </VStack>
            ) : (
              <Text
                fontSize="lg"
                fontWeight="bold"
                color="gray.500"
                textAlign="center"
                p={4}
                borderRadius="md"
              >
                No Off Ramp Data Available
              </Text>
            )}
          </VStack>
        ) : (
          <>
            <Grid
              gridTemplateColumns={{
                base: 'repeat(2, auto)',
                md: 'repeat(3, auto)'
              }}
              rowGap={2}
              w="100%"
              justifyContent="space-between"
            >
              <Stack>
                <HStack>
                  <Text fontSize="sm" color="gray">
                    Transaction ID:
                  </Text>
                  <CopyableText title="Order ID" fontSize="sm" color="white">
                    {falconTransaction?.transactionId}
                  </CopyableText>
                </HStack>
                <HStack>
                  <Text fontSize="sm" color="gray">
                    Client Transaction ID:
                  </Text>
                  <CopyableText title="Order ID" fontSize="sm" color="white">
                    {falconTransaction?.clientTxnId}
                  </CopyableText>
                </HStack>
              </Stack>
            </Grid>
            <Grid
              gridTemplateColumns="repeat(4, 1fr) auto"
              gap={2}
              alignItems="flex-start"
              w="100%"
              textAlign="start"
            >
              <Stack direction="column" spacing={1}>
                <Text fontSize="sm" color="gray">
                  Source Amount
                </Text>
                <Text fontSize="sm" color="white">
                  {`${falconTransaction.sourceAmount} ${falconTransaction.sourceCurrency}`}
                </Text>
              </Stack>
              <Stack direction="column" spacing={1}>
                <Text fontSize="sm" color="gray">
                  Payout amount
                </Text>
                <Text fontSize="sm" color="white">
                  {`${falconTransaction?.payoutAmount} ${falconTransaction?.payoutCurrency}`}
                </Text>
              </Stack>
              {show && (
                <Stack direction="column" spacing={1}>
                  <Text fontSize="sm" color="gray">
                    {show
                      .replace(/([A-Z])/g, ' $1')
                      .split(' ')
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(' ')}
                  </Text>
                  <Text fontSize="sm" color="white">
                    {falconTransaction[show] || '-'}
                  </Text>
                </Stack>
              )}
              <Stack direction="column" spacing={1}>
                <Text fontSize="sm" color="gray">
                  Client
                </Text>
                <Text fontSize="sm" color="white">
                  {falconTransaction?.client}
                </Text>
              </Stack>
              <Stack direction="column" spacing={1}>
                <Text fontSize="sm" color="gray">
                  Exchange Rate
                </Text>
                <Text fontSize="sm" color="white">
                  {`${falconTransaction.exchangeRate}`}
                </Text>
              </Stack>
              <Button
                size="sm"
                rounded="md"
                color="black"
                colorScheme="brand"
                onClick={() => {
                  onOrderDetailsClick(falconTransaction?.transactionId);
                }}
              >
                Details
              </Button>
            </Grid>
          </>
        )}
      </VStack>
    </Box>
  );
};

export default FalconTransactionCard;
