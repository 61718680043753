import {
  Divider,
  Grid,
  Heading,
  Spinner,
  Table,
  Tbody,
  Text,
  Thead,
  Tr,
  Td,
  VStack,
  HStack,
  Button,
  useDisclosure,
  IconButton,
  Switch,
  FormControl,
  FormLabel,
  Box
} from '@chakra-ui/react';
import ContentLayout from '../../layouts/ContentLayout';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import {
  createPot,
  deletePot,
  getForexRate,
  getRdaPots,
  getRdaPotsLast30Days
} from '../../store/actions/rdaActions';
import {
  getRdaGbpBalance,
  getYblBalance,
  getRdaInrBalance
} from '../../store/actions/rdaDashboardActions';
import Pagination from '../../components/Pagination';
import { tableCellStyles } from '../../components/NrOnboard/constants';
import dayjs from 'dayjs';
import ModalLayout from '../../components/Modal/ModalLayout';

import ConfirmModal from '../../components/Modal/ConfirmForceInitiate';
import { MdOutlineDelete } from 'react-icons/md';
import RdaFxRate from '../../components/Rda/FxRate';
import { updateRdaReqData } from '../../store/actions/reqDataActions';
import GenericUpdateModal from '../../components/Modal/GenericUpdateModal';
import CreatePot from '../../components/Rda/modal/CreatePot';
import { setAlert } from '../../store/actions/alertActions';
import BalanceTemplate from '../../components/RdaDashboard/Balances/BalanceTemplate';
import useAuthRole from '../../hooks/useAuthRole';
import { ROLES } from '../../constants/index';
import { MdDeleteOutline } from 'react-icons/md';

const Rda = ({}) => {
  const { hasAnyAuthority } = useAuthRole();
  const {
    isOpen: isCreateModalOpen,
    onClose: onCreateModalClose,
    onOpen: onCreateModalOpen
  } = useDisclosure();

  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDeleteModalOpen,
    onClose: onDeleteModalClose
  } = useDisclosure();
  const dispatch = useDispatch();

  const { isLoading, data: pots } = useSelector((state) => state?.rda);
  const [selectedPot, setSelectedPot] = useState(null);
  const [btnLoading, setBtnLoading] = useState(false);

  useEffect(() => {
    dispatch(getRdaPotsLast30Days());
  }, [dispatch]);

  const cellStyles = tableCellStyles();

  const handleCreatePot = ({
    gbpValue,
    fxRate,
    inrCredited,
    googleFxRate,
    reference
  }) => {
    setBtnLoading(true);
    if (Math.abs(gbpValue * fxRate - inrCredited) !== 0) {
      dispatch(
        setAlert({
          title: 'INR amount was incorrectly entered',
          status: 'error'
        })
      );
      setBtnLoading(false);
      return;
    }

    dispatch(
      createPot({
        amount: gbpValue,
        gbpValue,
        fxRate,
        inrCredited,
        googleFxRate,
        reference,
        onComplete: () => {
          setBtnLoading(false);
          onCreateModalClose();
        },
        onSuccess: () => {
          dispatch(getRdaPotsLast30Days());
        }
      })
    );
  };

  const handleDeletePot = () => {
    setBtnLoading(true);
    dispatch(
      deletePot({
        potId: selectedPot,
        onComplete: () => {
          setBtnLoading(false);
          onDeleteModalClose();
        },
        onSuccess: () => {
          setSelectedPot(null);
          dispatch(getRdaPotsLast30Days());
        }
      })
    );
  };

  return (
    <>
      <ModalLayout isOpen={isCreateModalOpen} onClose={onCreateModalClose}>
        <CreatePot
          onSubmit={handleCreatePot}
          onCancel={onCreateModalClose}
          isLoading={btnLoading}
        />
      </ModalLayout>
      <ModalLayout
        isOpen={isDeleteModalOpen}
        onClose={() => {
          onDeleteModalClose();
          setSelectedPot(null);
        }}
      >
        <ConfirmModal
          prompt={'Are you sure you want to archive this pot?'}
          handleSubmit={handleDeletePot}
          isLoading={btnLoading}
        />
      </ModalLayout>

      {/* <ContentLayout>*/}
      <VStack
        gap={'30px'}
        overflowY={{ base: 'initial', lg: 'scroll' }}
        overflowX={'hidden'}
        h={{ lg: '90vh' }}
        py={10}
        px={{ base: 4, sm: 10 }}
        alignItems={'stretch'}
        className="scroll"
      >
        <Heading align="center" textColor={'white'} size={'md'}>
          RDA
        </Heading>
        <HStack width={'full'} justifyContent={'space-between'}>
          <HStack width="100%" justifyContent={'space-around'} gap="10px">
            <BalanceTemplate
              title={'RDA GBP Balance'}
              action={getRdaGbpBalance}
            />
            <BalanceTemplate
              title={'RDA INR Balance'}
              action={getYblBalance}
              args={{ country: 'india' }}
            />
          </HStack>
          {hasAnyAuthority(ROLES.YBL_RDA_MANAGER) && (
            <Button
              variant={'outline'}
              colorScheme="brand"
              onClick={onCreateModalOpen}
            >
              Create Pot
            </Button>
          )}
        </HStack>
        {isLoading ? (
          <Spinner />
        ) : (
          pots?.length > 0 && (
            <Table>
              <Thead>
                <Tr>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      Created At
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      Google Fx Rate
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      GBP Booked
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      Fx Rate
                    </Text>
                  </Td>
                  <Td style={cellStyles}>
                    <Text color={'gray'} fontSize={'medium'}>
                      INR Credited
                    </Text>
                  </Td>
                </Tr>
              </Thead>
              <Tbody>
                {pots?.map((pot) => (
                  <Tr key={pot?.createdAt}>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {new Intl.DateTimeFormat('en-GB').format(
                          new Date(pot?.createdAt)
                        )}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {pot?.googleFxRate}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {pot?.gbpValue}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {pot?.fxRate}
                      </Text>
                    </Td>
                    <Td style={cellStyles}>
                      <Text color={'white'} fontSize={'medium'}>
                        {pot?.fxRate * pot?.gbpValue}
                      </Text>
                    </Td>
                    {/* <Td style={cellStyles}>
                      {hasAnyAuthority(ROLES.YBL_RDA_MANAGER) && (
                        <IconButton
                          icon={<MdDeleteOutline />}
                          colorScheme="red"
                          onClick={() => {
                            setSelectedPot(pot?.id);
                            onDeleteModalOpen();
                          }}
                          size="sm"
                          isLoading={btnLoading}
                          _hover={{ color: 'white' }}
                          _active={{ bg: 'red.700' }}
                        />
                      )}
                    </Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )
        )}
        {!isLoading && pots.length === 0 && (
          <Text color={'white'} fontSize={'medium'}>
            No pots available
          </Text>
        )}
      </VStack>
      {/* <Divider
            style={{ margin: '0' }}
            borderColor="whiteAlpha.300"
            orientation="vertical"
          ></Divider>
        </Grid>
      </ContentLayout> */}
    </>
  );
};

export default Rda;
