import dayjs from 'dayjs';
import { CARD_TYPE } from '../../constants';
import { formatDate } from '../../utils';

const genericCardFactory = ({ cardData, cardType, ...props }) => {
  let header,
    headerGridTemplateColumns = null,
    content,
    contentGridTemplateColumns = null;
  switch (cardType) {
    case CARD_TYPE.OFF_RAMP_CARD: {
      header = [
        {
          name: 'Transaction ID',
          value: cardData?.txnId || 'N/A',
          isCopyable: true
        },
        {
          name: 'Transaction Hash',
          value: cardData?.txnHash || 'N/A',
          isCopyable: true
        },
        {
          name: 'Status',
          value: cardData?.status || 'N/A'
        },
        {
          name: 'Receive Amount',
          value: cardData?.receiveAmount
            ? cardData.receiveAmount.toString()
            : 'N/A'
        },
        {
          name: 'Crypto Currency',
          value: cardData?.cryptoCurrency || 'N/A'
        },
        {
          name: 'Crypto Amount',
          value: cardData?.cryptoAmount
            ? cardData.cryptoAmount.toString()
            : 'N/A'
        },
        {
          name: 'Payout ID',
          value: cardData?.payoutId || 'N/A',
          isCopyable: true
        },
        {
          name: 'Payout Status',
          value: cardData?.payoutStatus || 'N/A'
        },
        {
          name: 'Transfer Type',
          value: cardData?.transferType || 'N/A'
        },
        {
          name: 'Created At',
          value: cardData?.createdAt
            ? dayjs(cardData.createdAt).format('YYYY-MM-DD HH:mm:ss')
            : 'N/A'
        },
        {
          name: 'Updated At',
          value: cardData?.updatedAt
            ? dayjs(cardData.updatedAt).format('YYYY-MM-DD HH:mm:ss')
            : 'N/A'
        }
      ];
      content = [
        {
          name: 'Transaction Details',
          value: `Crypto Amount: ${cardData?.cryptoAmount || 'N/A'} ${
            cardData?.cryptoCurrency || ''
          }`
        }
      ];
    }
    case CARD_TYPE.FALCON_TRANSACTION_CARD: {
      header = [
        {
          name: 'Transaction ID',
          value: cardData?.transactionId || 'N/A',
          isCopyable: 'true'
        },
        {
          name: 'Client',
          value: cardData?.client || 'N/A'
        },
        {
          name: 'Client Transaction ID',
          value: cardData?.clientTxnId || 'N/A',
          isCopyable: 'true'
        },
        {
          name: 'Source Currency',
          value: cardData?.sourceCurrency || 'N/A'
        },
        {
          name: 'Source Amount',
          value: cardData?.sourceAmount
            ? cardData.sourceAmount.toString()
            : 'N/A'
        },
        {
          name: 'Payout Currency',
          value: cardData?.payoutCurrency || 'N/A'
        },
        {
          name: 'Payout Amount',
          value: cardData?.payoutAmount
            ? cardData.payoutAmount.toString()
            : 'N/A'
        },
        {
          name: 'Exchange Rate',
          value: cardData?.exchangeRate
            ? cardData.exchangeRate.toString()
            : 'N/A'
        }
      ];
      headerGridTemplateColumns = true;
      contentGridTemplateColumns = true;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }
    case CARD_TYPE.GOLDEN_TICKET_CARD: {
      header = [
        {
          name: 'Ticket ID',
          value: cardData.ticketId,
          isCopyable: true
        },
        {
          name: 'Status',
          value: cardData.status
        },
        {
          name: 'Created At',
          value: formatDate(cardData.creationDate)
        }
      ];
      content = [
        {
          name: 'Redeemed by',
          value: cardData.referredUserId || '-  '
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.LULU_ORDER_CARD: {
      header = [
        {
          name: 'Order ID',
          value: cardData.orderId,
          isCopyable: true
        },
        {
          name: 'Status',
          value: cardData.status || '-'
        },
        {
          name: 'Created At',
          value: formatDate(cardData.createdAt) || '-'
        }
      ];
      content = [
        {
          name: 'Txn Ref ID',
          value: cardData.transactionRefId || '-',
          isCopyable: true
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.JOB_CARD: {
      header = [
        {
          name: '',
          value: cardData.jobId,
          isCopyable: true
        },
        {
          name: '',
          value: cardData.jobStatus || '-'
        },
        {
          name: 'Created At',
          value: formatDate(cardData.createDate) || '-'
        }
      ];
      content = [
        {
          name: 'Type',
          value: cardData.jobType || '-'
        },
        {
          name: 'Remarks',
          value: cardData.remarks || '-',
          isPopup: true,
          onClick: props.onRemarksClick
        },
        {
          name: 'Additional Info',
          value: cardData.additionalInfo || '-',
          isPopup: true,
          onClick: props.onAdditionalInfoClick
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.LULU_FUNNEL_ORDER_CARD: {
      header = [
        {
          name: 'Order ID',
          value: cardData.orderId,
          isCopyable: true
        },
        {
          name: 'Status',
          value: cardData.status || '-'
        },
        {
          name: 'Created At',
          value: formatDate(cardData.createdAt) || '-'
        }
      ];
      content = [
        {
          name: 'User',
          value: cardData.userName || '-'
        },
        {
          name: 'Transaction ID',
          value: cardData.transactionRefId || '-',
          isCopyable: true
        },
        {
          name: 'Instrument',
          value: cardData.instrument || '-'
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.REDEEMED_COINS_CARD: {
      header = [
        {
          name: 'Reward ID',
          value: cardData.id,
          isCopyable: true
        },
        {
          name: 'Status',
          value: cardData.status || '-'
        },
        {
          name: 'Created At',
          value: formatDate(cardData.createDate) || '-'
        }
      ];
      content = [
        {
          name: 'Vance Coins',
          value: cardData.points || '-'
        },
        {
          name: 'Cash Reward',
          value: cardData.currency + ' ' + cardData.cash
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.OPS_TICKET_CARD: {
      header = [
        {
          name: 'Reference ID',
          value: cardData?.referenceId,
          isCopyable: true
        },
        {
          name: 'Assigned To',
          value: cardData?.assignedTo?.name
        },
        {
          name: 'Status',
          value: cardData?.status
        }
      ];
      content = [
        {
          name: 'Notes',
          value: cardData?.notes,
          isPopup: true,
          onClick: props.onNotesPreviewClick
        },
        {
          name: 'Ticket ID',
          value: cardData?.ticketId,
          isCopyable: true
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.NRONBOARD: {
      header = [
        {
          name: 'UserID',
          value: cardData?.userId
        },
        {
          name: 'Created On',
          value: dayjs(cardData?.createdDate).format('DD/MM/YYYY')
        }
      ];
      content = [
        {
          name: 'Active Step',
          value: cardData?.activeStep
        },
        {
          name: 'Country',
          value: cardData?.country
        }
      ];
      headerGridTemplateColumns = null;
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    case CARD_TYPE.VDA_FUNNEL: {
      content = [
        {
          name: 'Saber Order ID',
          value: cardData?.saberOrderId
        },
        {
          name: 'Saber Status',
          value: cardData?.saberOrderStatus
        },
        {
          name: 'Saber Transaction Hash',
          value: cardData?.transactionHash
        },
        {
          name: 'Saber Amount Credited',
          value: cardData?.saberAmountCredited
        }
      ];
      header = [
        {
          name: 'OnRampFulfillment Status',
          value: cardData?.onRampFulfillmentStatus
        },
        {
          name: 'Vance Order ID',
          value: cardData?.vanceOrderId
        },
        {
          name: 'Vance Payment Collection Status',
          value: cardData?.vancePaymentCollectionStatus
        },
        {
          name: 'Vance Order Amount',
          value: cardData?.vanceOrderAmount
        }
      ];
      headerGridTemplateColumns = '1fr 1fr 1fr 1fr';
      contentGridTemplateColumns = null;
      return {
        header,
        content,
        headerGridTemplateColumns,
        contentGridTemplateColumns
      };
    }

    default: {
      console.log('invalid card type');
      return null;
    }
  }
};

export default genericCardFactory;
