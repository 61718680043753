export default function thunesApi(fetch, baseUrl) {
  return {
    getOnHoldOrders({ pageNo = 0, pageSize = 10 }) {
      return fetch.get(
        `${baseUrl}/thunes-collection?page=${pageNo}&page_size=${pageSize}`
      );
    },

    forceInitiateTransfer({ paymentOrderId }) {
      return fetch.post(
        `${baseUrl}/thunes-collection/force-initiate/${paymentOrderId}`
      );
    },

    getOnHoldFulfillments({ pageNo = 0, pageSize = 10 }) {
      return fetch.get(
        `${baseUrl}/order-fulfillment/on-hold?page=${pageNo}&page_size=${pageSize}`
      );
    },

    getOnHoldFulfillmentsV2({ orderId }) {
      return fetch.get(`${baseUrl}/order-fulfillment/on-hold/${orderId}`);
    },
    getOnHoldFulfillmentsV3(reqBody) {
      return fetch.post(`${baseUrl}/order-fulfillment/V3/on-hold`, reqBody);
    },
    forceInitiateFulfillment({ fulfillmentId }) {
      return fetch.post(
        `${baseUrl}/order-fulfillment/force-initiate/${fulfillmentId}`
      );
    }
  };
}
